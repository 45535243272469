import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import OfferPreview from "../components/OfferPreview";
import PageInfo from "../components/PageInfo";
import slugify from "slugify";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";
import Seo from "../components/seo";

const LayoutWrapper = styled.div`
  // background-color: white;
  position: relative;
  color: black;
  // margin-top: 100px;
`;

const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  margin-bottom: 100px;
  margin-top: 50px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1349px) {
    width: 90%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
  }
`;

const pageData = {
  header: "Oferty pracy",
  mainTitle: "Kariera",
  subtitle: "Zapoznaj się z najnowszymi ofertami",
};

const OffersPage = ({ data }) => {
  const {
    allDatoCmsOffer: { nodes },
  } = data;
  return (
    <>
      <Layout>
        <section>
          <LayoutWrapper className="offerPage">
            <PageInfo
              header={pageData.header}
              mainTitle={pageData.mainTitle}
              subtitle={pageData.subtitle}
            />
            <ArticlesWrapper>
              {nodes
                .map(({ title, date, offerContent, offerModularContent }) => (
                  <OfferPreview
                    key={title}
                    title={title}
                    date={date}
                    offerContent={offerContent}
                    offerModularContent={[
                      offerModularContent.map((el) => {
                        return el.headingContent;
                      }),

                      offerModularContent.map((el) => {
                        return el.paragraphContent;
                      }),
                    ]}
                    //   image={featuredImage.fluid}
                    slug={slugify(title, { lower: true })}
                  />
                ))
                .reverse()}
              {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
            </ArticlesWrapper>
          </LayoutWrapper>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>
          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    allDatoCmsOffer {
      nodes {
        title
        date
        offerContent

        offerModularContent {
          ... on DatoCmsHeading {
            headingContent
          }
          ... on DatoCmsParagraph {
            paragraphContent
          }
        }
      }
    }
  }
`;

export const Head = () => <Seo title="Kariera w JB Multimedia" />;

export default OffersPage;
