import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { List } from "./List";

const PreviewWrapper = styled(Link)`
  display: block;
  width: 100%;
  padding: 10px;
  transition: 0.2s;
`;

const PreviewInfoLabel = styled.div`

  padding: 5px 15px;
  h2,
  p {
    white-space: break-spaces;
    font-weight: 600;
    letter-spacing: 2px;
    width: 90%;
    text-align: left;

}
p.offerName {
  font-size: 2.9em;
  font-weight: 400;
  margin: 0
}
}
  }
`;

// const StyledImage = styled(Image)`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

const OfferPreview = ({
  title,
  excerpt,
  slug,
  date,
  offerContent,
  offerModularContent,
}) => (
  <PreviewWrapper className="offer-list">
    {/* <StyledImage fluid={image} /> */}
    <PreviewInfoLabel className="offer-list-container">
      <h2>{title}</h2>
      <p>{date}</p>

      <p>{offerModularContent[0]}</p>

      <List
        // index={offerModularContent.indexOf(offerModularContent[0])}
        // index={""}
        header={<p>{offerContent}</p>}
        paragraph={<p>{offerModularContent[1]}</p>}
      />
    </PreviewInfoLabel>
  </PreviewWrapper>
);

export default OfferPreview;
