import React, { useState } from "react";

export function List(props) {
  const { header, paragraph, index, listHeader } = props;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    // const spans = document.querySelectorAll(
    //   "#advList .page-content ul li:nth-child(even)"
    // );

    // spans[index].classList.toggle("open");
    // spans[index].classList.toggle("open");
  };
  return (
    <>
      <section id="advList" className="adv-list">
        <div className="page-content">
          <h3 className="listHeader">{listHeader}</h3>
          <div>
            <ul>
              <li onClick={() => handleClick(index)}>
                <span>
                  <strong className={activeIndex === index ? "open" : ""}>
                    {header}
                  </strong>
                </span>
              </li>
              <li className={activeIndex === index ? "open" : ""}>
                <div>{paragraph}</div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
